import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from 'react-free-style';
import { connect } from 'react-redux';
import * as elements from '../../styles/elements';

import { Spinner } from '@united-talent-agency/components';

import Wrapper from './Wrapper';
import CallListWrapper from './CallListWrapper';

import { setDesk as setDeskAction } from '@united-talent-agency/julius-frontend-store';
import { datadogRum } from '@datadog/browser-rum';
import { WIDTH_LIMIT } from '../../support/windowSize';
import { getStatuses } from '../../api/statuses';
import { useDebouncedCallback } from 'use-debounce';
import { getCommunications, DEFAULT_CALL_FETCH_LIMIT } from '../../api/communications';
import { notify } from 'react-notify-toast';
import {
  fullRefresh as handleFullRefreshResult,
  loadMore as handleLoadMoreResult,
} from './CallListWrapper/Helper';
import useQueryParamsSync from '../../hooks/useQueryParamsSync';
import { get } from '../../api';

const REFRESH_RATE = (process.env.REACT_APP_REFRESH_RATE_SECONDS || 10) * 1000;

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const Component = ({ user, desk, dispatch, status, isLoading, hideFutureCalls, styles }) => {
  const desksFilters = useRef(user?.phoneSheetFilter ?? []);

  const getStoredFiltersByDeskId = (deskId) => {
    return desksFilters.current?.find((searchParams) => searchParams.deskId === deskId);
  };

  const userStoredFilters = useMemo(
    () => getStoredFiltersByDeskId(desk?._id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [desk?._id]
  );

  const { search, updateFilters, updateDeskId } = useQueryParamsSync({
    userStoredFilters,
    deskId: desk?._id,
  });

  // Refs
  const prevWidth = useRef(WIDTH_LIMIT + 1);

  // States
  const [fetchCounts, setFetchCounts] = useState({});
  const [totalCounts, setTotalCounts] = useState({});
  const [filtersExpanded, setFiltersExpanded] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectExcept, setSelectExcept] = useState(new Set());
  const [deskStatuses, setDeskStatuses] = useState(status);
  const [communications, setCommunications] = useState([]);
  const [statusOfLoadingMore, setStatusOfLoadingMore] = useState(null);

  useEffect(() => {
    if (!deskStatuses?.length && status) {
      setDeskStatuses(status);
    }
  }, [deskStatuses?.length, status, deskStatuses]);

  useEffect(() => {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }
  }, []);

  useEffect(() => {
    const windowResizeListener = () => {
      // While resizing, if screen size got smaller than mediumBreakpoint, filters should be collapsed
      if (prevWidth.current > WIDTH_LIMIT && window.innerWidth <= WIDTH_LIMIT) {
        setFiltersExpanded(false);
      }
      prevWidth.current = window.innerWidth;
    };

    windowResizeListener();

    window.addEventListener('resize', windowResizeListener);

    return () => {
      window.removeEventListener('resize', windowResizeListener);
    };
  }, []);

  const fetchDesk = useCallback(
    async (desk) => {
      await dispatch(setDeskAction(desk || {}));
    },
    [dispatch]
  );

  useEffect(() => {
    // Runs when user changes only
    if (desk) {
      fetchDesk(desk);
    } else if (user?.deskIds?.length) {
      fetchDesk(user.deskIds[0]);
    }
  }, [desk, fetchDesk, user.deskIds]);

  useEffect(() => {
    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });

      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }
  }, [user]);

  const fullRefresh = useCallback(() => {
    const deskStatuesesNames = deskStatuses?.map((s) => s.status);
    const statuses = (search?.filter?.length ? search?.filter : deskStatuesesNames) ?? [];

    const promises = statuses
      ?.filter((_status) => _status !== '')
      ?.map((item) => {
        let limit = DEFAULT_CALL_FETCH_LIMIT;
        if (!!fetchCounts[item] && DEFAULT_CALL_FETCH_LIMIT < fetchCounts[item]) {
          limit = fetchCounts[item];
        }
        return getCommunications(
          { ...search, hideFutureCalls, filter: item },
          {
            skip: 0,
            limit,
          }
        );
      });

    return Promise.all(promises)
      .then((results) =>
        handleFullRefreshResult(
          results,
          deskStatuesesNames,
          setFetchCounts,
          setTotalCounts,
          setCommunications
        )
      )
      .catch(() => {
        notify.show('Failed to load calls', 'error');
      });
  }, [deskStatuses, fetchCounts, hideFutureCalls, search]);

  const debouncedFullRefresh = useDebouncedCallback(fullRefresh, 500, {
    leading: false,
    trailing: true,
    maxWait: REFRESH_RATE,
  });

  const resetSearch = useCallback(() => {
    updateFilters({ filters: undefined, favorite: undefined, query: undefined });
  }, [updateFilters]);

  const fetchDesksFilters = async () => {
    const response = await get(`/user/${user._id}`);
    desksFilters.current = response?.body?.phoneSheetFilter;
  };

  const handleOnChangeDesk = useCallback(
    async (selectedDesk) => {
      // Update desks filters
      await fetchDesksFilters();
      updateDeskId({
        ...getStoredFiltersByDeskId(selectedDesk?._id),
        deskId: selectedDesk?._id,
      });
      fetchDesk(selectedDesk);
      const statuses = await getStatuses(selectedDesk._id);
      setDeskStatuses(statuses);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchDesk, updateDeskId]
  );

  const navigate = useCallback(
    (_search) => {
      updateFilters(_search);
    },
    [updateFilters]
  );

  /**
   * Load more calls per status
   *
   * @param {Search} search: params for search
   * @param {string} status: status of calls to load
   **/
  const loadMore = useCallback(
    (_status) => {
      const params = {
        ...search,
        filter: _status,
        hideFutureCalls,
      };
      setStatusOfLoadingMore(_status);
      getCommunications(params, { skip: fetchCounts[_status] })
        .then((result) => {
          handleLoadMoreResult(
            result,
            _status,
            fetchCounts,
            setFetchCounts,
            communications,
            setCommunications
          );
          setStatusOfLoadingMore(null);
        })
        .catch(() => {
          setStatusOfLoadingMore(null);
          notify.show('Failed to load more calls', 'error');
        });
    },
    [communications, fetchCounts, hideFutureCalls, search]
  );

  useEffect(() => {
    // either changing desk and persisting filter call refreshCalls
    // to prevent consequent refreshCalls, use debounce here
    debouncedFullRefresh();
    const subscription = setInterval(() => {
      debouncedFullRefresh();
    }, REFRESH_RATE);

    return () => {
      clearInterval(subscription);
    };
  }, [debouncedFullRefresh, deskStatuses, status]);

  if (!user?.deskIds?.length) {
    const hasDeskAssigned = !!user?.deskIds?.[0]?._id;

    if (!isLoading && !hasDeskAssigned) {
      return (
        <h5 className="m-4">
          No current office groups configured. Please contact the Service Desk at x3900
        </h5>
      );
    }

    return (
      <div className={styles.spinner}>
        <Spinner size={60} />
      </div>
    );
  }

  if (!search?.deskId) {
    return <></>;
  }

  return (
    <Wrapper
      user={user}
      search={search}
      fetchCounts={fetchCounts}
      totalCounts={totalCounts}
      filtersExpanded={filtersExpanded}
      navigate={navigate}
      setSelectAll={setSelectAll}
      setSelectExcept={setSelectExcept}
      changeFiltersExpanded={setFiltersExpanded}
      onChangeDesk={handleOnChangeDesk}
      resetSearch={resetSearch}
      deskStatuses={deskStatuses}
      fullRefresh={debouncedFullRefresh}
    >
      <CallListWrapper
        communications={communications}
        statusOfLoadingMore={statusOfLoadingMore}
        loadMore={loadMore}
        fullRefresh={debouncedFullRefresh}
        search={search}
        fetchCounts={fetchCounts}
        totalCounts={totalCounts}
        selectAll={selectAll}
        selectExcept={selectExcept}
        setSelectAll={setSelectAll}
        setFetchCounts={setFetchCounts}
        setTotalCounts={setTotalCounts}
        setSelectExcept={setSelectExcept}
      />
    </Wrapper>
  );
};

const withStyles = styled({
  spinner: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
  },
  link: elements.link,
});

const withState = connect(({ user, desk = {}, isLoading }) => {
  const hideFutureCalls =
    user && desk.current && desk.current.settings && desk.current.settings.hideFutureCalls;

  return { user, hideFutureCalls, desk: desk.current, isLoading, status: desk.status || [] };
});

const Home = withStyles(withState(Component));

export default Home;
