import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import download from 'downloadjs';
import { connect } from 'react-redux';
import { styled } from 'react-free-style';
import { notify } from 'react-notify-toast';

import { Icons, colors } from '@united-talent-agency/components';
import {
  listCalls,
  requestPromise,
  updateManyDeskSettings,
} from '@united-talent-agency/julius-frontend-store';
import { getStatuses, updateStatuses } from '../../../api/statuses';

import ArchiveSearch from '../ArchiveSearch';
import { fromDateString, toUnixTime } from '../../../support/date';
import {
  createShortcuts,
  filterInputEvent,
  keyboard,
  stringifyKey,
} from '../../../support/keyboard';
import { apiServerUrl } from '../../../support/urls';
import HeaderMenu from '../../../components/HeaderMenu';
import SettingsModal from '../../../components/SettingsModal';
import SearchBar from '../../../components/SearchBar';

const Component = ({ user, search, desk, dispatch, deskStatuses, styles, navigate, onCallAdd }) => {
  // Refs
  const searchInput = useRef();

  // States
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isCsvFileDownloading, setIsCsvFileDownloading] = useState(false);
  const [isPdfFileDownloading, setIsPdfFileDownloading] = useState(false);
  const [isSendingPhonesheetEmail, setIsSendingPhonesheetEmail] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [statuses, setStatuses] = useState(deskStatuses);
  const [settingsErrorState, setSettingsErrorState] = useState(false);

  useEffect(() => {
    const keyboardListener = filterInputEvent(
      createShortcuts({
        [stringifyKey('/')]: (e) => {
          e.preventDefault();

          return searchInput.current?.focus && searchInput.current?.focus();
        },
      })
    );

    keyboard.addListener(keyboardListener);

    return () => {
      keyboard.removeListener(keyboardListener);
    };
  }, []);

  useEffect(() => {
    getStatuses(desk._id)
      .then(setStatuses)
      .catch((error) => {
        console.error(error);
      });
  }, [desk._id]);

  const closeSettings = useCallback(() => {
    const { favorite, filter: status, query, page } = search || {};
    const startDate =
      search && search.startDate ? toUnixTime(fromDateString(search.startDate)) : undefined;
    const endDate =
      search && search.endDate ? toUnixTime(fromDateString(search.endDate, 24)) : undefined;

    getStatuses(desk._id)
      .then((_statuses) => {
        dispatch(
          listCalls({
            favorite,
            status,
            query,
            startDate,
            endDate,
            page,
            desk: desk._id,
          })
        );
        setStatuses(_statuses);
        setIsSettingsModalOpen(false);
        setSettingsErrorState(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [desk, dispatch, search]);

  const openSettings = useCallback(() => {
    setIsSettingsModalOpen(true);
  }, []);

  const toggleArchive = useCallback(() => {
    setIsArchiveModalOpen((oldState) => !oldState);
  }, []);

  // TODO: just, no
  // send request to backend and let it handle
  // the querying there, not in frontend
  const requestFile = useCallback(
    async (contentType, fileName) => {
      const fileRequest = await requestPromise(
        user,
        apiServerUrl,
        '/call_todo',
        'GET',
        null,
        [
          { $populate: 'recipientId' },
          { $populate: 'companyId' },
          { $populate: 'deskId' },
          { '$sort[occurrence_date]': '-1' },
          { $excludes: 'personId.profile_pic' },
          { 'status[$ne]': 'AR' },
          { $limit: '500' },
          { timezone: new Date().getTimezoneOffset() },
          { deskId: desk._id },
        ],
        contentType
      );
      const responseBlob = await fileRequest.blob();
      download(responseBlob, fileName, contentType);
    },
    [desk._id, user]
  );

  const downloadFile = useCallback(
    async (fileType) => {
      let contentType, fileName;
      if (fileType === 'pdf') {
        setIsPdfFileDownloading(true);
        contentType = 'application/pdf';
        fileName = 'phone-sheet.pdf';
      } else {
        setIsCsvFileDownloading(true);
        contentType = 'text/csv';
        fileName = 'phone-sheet.csv';
      }

      await requestFile(contentType, fileName);

      if (fileType === 'pdf') {
        setIsPdfFileDownloading(false);
      } else {
        setIsCsvFileDownloading(false);
      }
    },
    [requestFile]
  );

  const downloadPdf = useCallback(() => {
    if (!isPdfFileDownloading) {
      downloadFile('pdf');
    }
  }, [downloadFile, isPdfFileDownloading]);

  const downloadCsv = useCallback(() => {
    if (!isCsvFileDownloading) {
      downloadFile('csv');
    }
  }, [downloadFile, isCsvFileDownloading]);

  // TODO: again, no
  // let backend handle the querying there, not in frontend
  const requestEmail = useCallback(async () => {
    const response = await requestPromise(
      user,
      apiServerUrl,
      '/call_todo',
      'GET',
      null,
      [
        { $populate: 'recipientId' },
        { $populate: 'companyId' },
        { $populate: 'deskId' },
        { '$sort[occurrence_date]': '-1' },
        { $excludes: 'personId.profile_pic' },
        { 'status[$ne]': 'AR' },
        { $limit: '500' },
        { timezone: new Date().getTimezoneOffset() },
        { deskId: desk._id },
      ],
      'text/email'
    );

    const data = await response;

    return data;
  }, [desk._id, user]);

  const sendEmail = useCallback(async () => {
    setIsSendingPhonesheetEmail(true);
    const data = await requestEmail();
    if (data.success) {
      notify.show('Email Sent!', 'success');
    } else {
      notify.show('Email failed to send...', 'error');
    }
    setIsSendingPhonesheetEmail(false);
  }, [requestEmail]);

  const emailPhonesheet = useCallback(() => {
    if (!isSendingPhonesheetEmail) {
      sendEmail();
    }
  }, [isSendingPhonesheetEmail, sendEmail]);

  const signOut = useCallback(() => {
    localStorage.removeItem('uta.auth');
    // TODO: Tracking
    // Logout user
    // userId: null,
    // userName: null,
    // userEmail: null,
    window.location = '/login';
  }, []);

  const settingsFieldsAreValid = useMemo(() => {
    const _statuses = [].concat.apply(
      [],
      statuses?.length ? statuses?.map((status) => Object.values(status)) : ['']
    );

    return !_statuses.includes('');
  }, [statuses]);

  const saveSettings = useCallback(
    async ({ statuses, hideFutureCalls, hideBulkEdits }) => {
      try {
        await updateStatuses(desk._id, statuses);
        await dispatch(
          updateManyDeskSettings(desk, {
            hideFutureCalls,
            hideBulkEdits,
          })
        );
        closeSettings();
      } catch (error) {
        console.error(error);
        notify.show(error.message, 'error');
      }
    },
    [closeSettings, desk, dispatch]
  );

  const addStatus = useCallback(() => {
    setStatuses((oldState) => [
      ...oldState,
      {
        order: oldState.length + 1,
        color: '',
        status: '',
        name: '',
        type: '',
        count: 0,
        deskId: (desk || {})._id || '',
      },
    ]);
  }, [desk]);

  const menuOptions = [
    { name: 'Print Phonesheet (PDF)', MenuOptionIcon: Icons['PrinterIcon'], onSelect: downloadPdf },
    {
      name: 'Download Phonesheet (CSV)',
      MenuOptionIcon: Icons['TableIcon'],
      onSelect: downloadCsv,
    },
    { name: 'Email Phonesheet', MenuOptionIcon: Icons['EmailIcon'], onSelect: emailPhonesheet },
    {
      name: 'DialLog Data Archive',
      MenuOptionIcon: Icons['PhoneOutgoingIcon'],
      onSelect: toggleArchive,
    },
    { name: 'Settings', MenuOptionIcon: Icons['SettingsIcon'], onSelect: openSettings },
    {
      name: 'Sign Out',
      MenuOptionIcon: Icons['LogOutIcon'],
      props: { hFlip: true },
      onSelect: signOut,
    },
  ];

  const handleOnSaveSettings = useCallback(
    async (settingsUpdate, callback) => {
      if (settingsFieldsAreValid) {
        await saveSettings(settingsUpdate);
        callback();
      } else {
        setSettingsErrorState(true);
      }
    },
    [saveSettings, settingsFieldsAreValid]
  );

  return (
    <div className={styles.headerContainer}>
      <div className={styles.searchColumn}>
        <SearchBar ref={searchInput} styles={styles} search={search} navigate={navigate} />
        <div className={styles.menuColumn}>
          <HeaderMenu menuOptions={menuOptions} MenuMainIcon={Icons['HamburgerMenuIcon']} />
        </div>
      </div>
      <SettingsModal
        navigate={navigate}
        search={search}
        desk={desk}
        statuses={statuses || []}
        isOpen={isSettingsModalOpen}
        onCancel={closeSettings}
        onSave={handleOnSaveSettings}
        onAddStatus={addStatus}
        onChangeStatuses={setStatuses}
        errorState={settingsErrorState}
      />
      <ArchiveSearch
        user={user}
        isOpen={isArchiveModalOpen}
        closeModal={toggleArchive}
        onPersonSelected={onCallAdd}
      />
    </div>
  );
};

const withStyles = styled({
  headerContainer: {
    background: colors.white,
    display: 'flex',
    flexDirection: 'row',
  },
  search: {
    width: '100%',
  },
  searchContainer: {
    margin: '10px',
  },
  searchInput: {
    '&&': {
      border: `1px solid ${colors.border}`,
      background: colors.white,
    },
  },
  multiselect: {
    display: 'flex',
    marginTop: 25,
    flex: 0.2,
    marginLeft: '10px',
    alignItems: 'center',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'flex-start',
    width: 200,
  },
  searchColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    margin: '10px 5px',
    justifyContent: 'center',
  },
});

const withState = connect((state) => {
  const { desk, user } = state;
  const { deskIds } = user;
  const { editCallTodo } = state.callForm;
  const { total, callTodos } = state.callTodo.calls;

  return { desk: desk.current, deskIds, editCallTodo, total, callTodos, user };
});

const Header = withState(withStyles(Component));

export default Header;
